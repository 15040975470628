












































































































































import { Component, Vue } from "vue-property-decorator";
import Banner from "./Banner.vue";

@Component({
  components: {
    Banner
  },
  metaInfo: {
    title: "Okul Desteği Başvurusu"
  }
})
export default class ProjectsView extends Vue {}
