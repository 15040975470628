












































import { Component, Vue } from "vue-property-decorator";

@Component
export default class ProjectsBanner extends Vue {
  get height() {
    switch (this.$vuetify.breakpoint.name) {
      case "sm":
        return 300;
      case "md":
        return 600;
      case "lg":
        return 600;
      case "xl":
        return 600;
      default:
        return 300;
    }
  }
}
